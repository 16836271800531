<script setup>
import { ref, watch, onMounted, nextTick } from 'vue'
import { createPopper } from '@popperjs/core'

let popperInstance = null

const show = ref(false)
const dropdown = ref(null)
const button = ref(null)

const props = defineProps({
  placement: {
    type: String,
    default: 'bottom-end'
  },
  autoClose: {
    type: Boolean,
    default: true
  }
})

const toggleDropdown = () => {
  show.value = props.autoClose ? false : !show.value
}

watch(show, (current) => {
  if (current) {
    nextTick(() => {
      popperInstance = createPopper(button.value, dropdown.value, {
        placement: props.placement,
        modifiers: [
          {
            name: 'preventOverflow',
            options: {
              altBoundary: true
            }
          }
        ]
      })
    })
  } else if (popperInstance) {
    setTimeout(() => {
      popperInstance.destroy()
      popperInstance = null
    }, 100)
  }
})

onMounted(() => {
  document.addEventListener('keydown', (e) => {
    if (e.key === 'Escape') {
      show.value = false
    }
  })
})
</script>

<template>
  <button ref="button" type="button" @click="show = true">
    <slot />
    <teleport v-if="show" to="#dropdown">
      <div>
        <div
          style="
            position: fixed;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            z-index: 99998;
            background: black;
            opacity: 0.2;
          "
          @click="show = false"
        />
        <div ref="dropdown" style="position: absolute; z-index: 99999" @click.stop="toggleDropdown">
          <slot name="dropdown" />
        </div>
      </div>
    </teleport>
  </button>
</template>
