<script setup>
import { Head, Link, useForm, router } from '@inertiajs/vue3'
import FullCalendar from '@fullcalendar/vue3'
import timeGridPlugin from '@fullcalendar/timegrid'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import esLocale from '@fullcalendar/core/locales/es'
import { computed, onUnmounted, ref } from 'vue'
import { useDate } from '@/Composables/useDate'
import { useConfirm } from '@/Composables/useConfirm'
import Modal from '@/Shared/Modal.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import TextInput from '@/Shared/TextInput.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'

const props = defineProps({
  group: {
    type: Object,
    required: true
  },
  games: {
    type: Array,
    required: true
  },
  availabilities: {
    type: Array,
    required: true
  }
})

const participants = computed(() => {
  return props.group.participants
})

const gameEvents = props.games.map((game) => {
  return {
    id: `game_${game.id}`,
    title: `${game.participant_one.name} vs ${game.participant_two.name}`,
    observations: game.observations,
    start: useDate(game.date_start).format('YYYY-MM-DD HH:mm:ss'),
    end: useDate(game.date_end).format('YYYY-MM-DD HH:mm:ss'),
    color: game.notified_at ? 'rgba(0, 128, 0, 0.8)' : 'rgba(255, 87, 34, 0.8)',
    textColor: '#ffffff',
    extendedProps: {
      type: 'game',
      notified: !!game.notified_at
    }
  }
})

function combineAvailabilities(availabilities) {
  const combinedAvailabilities = []
  const availabilityMap = {}

  availabilities.forEach((availability) => {
    const start = useDate(availability.date_start).format('YYYY-MM-DD HH:mm:ss')
    const end = useDate(availability.date_end).format('YYYY-MM-DD HH:mm:ss')
    const key = `${start}_${end}`

    if (!availabilityMap[key]) {
      availabilityMap[key] = {
        start,
        end,
        participants: [findParticipant(availability.available_id).name]
      }
    } else {
      availabilityMap[key].participants.push(findParticipant(availability.available_id).name)
    }
  })

  for (const key in availabilityMap) {
    const { start, end, participants } = availabilityMap[key]
    combinedAvailabilities.push({
      start,
      end,
      title: participants.join(', '),
      color: 'rgba(66, 135, 245, 0.3)',
      textColor: '#ffffff',
      display: 'background',
      extendedProps: {
        type: 'availability'
      }
    })
  }

  return combinedAvailabilities
}

const availabilitiesEvents = combineAvailabilities(props.availabilities)

const events = ref([...gameEvents, ...availabilitiesEvents])

const isMobile = ref(window.innerWidth <= 768)
const showModal = ref(false)

const form = useForm({
  participant_one_id: null,
  participant_two_id: null,
  date_start: null,
  date_end: null,
  observations: null
})

const updateDeviceType = () => {
  isMobile.value = window.innerWidth <= 768
}

onUnmounted(() => {
  window.removeEventListener('resize', updateDeviceType)
})

const handleDateClick = (selectInfo) => {
  const dateStart = useDate(selectInfo.dateStr).format('YYYY-MM-DDTHH:mm')
  const dateEnd = useDate(selectInfo.dateStr).add(1.5, 'hour').format('YYYY-MM-DDTHH:mm')

  form.date_start = dateStart
  form.date_end = dateEnd
  showModal.value = true
}

const createGame = () => {
  const dateStart = useDate(form.date_start).format('YYYY-MM-DD HH:mm:ss')
  const dateEnd = useDate(form.date_end).format('YYYY-MM-DD HH:mm:ss')

  const participantOne = findParticipant(form.participant_one_id)
  const participantTwo = findParticipant(form.participant_two_id)

  const event = {
    id: `game_${new Date().getTime()}`,
    title: `${participantOne?.name} vs ${participantTwo?.name}`,
    observations: form.observations,
    start: dateStart,
    end: dateEnd,
    color: '#f3722c',
    textColor: '#ffffff',
    extendedProps: {
      type: 'game'
    }
  }

  form.date_start = dateStart
  form.date_end = dateEnd

  form.post(`/rankings/phases/groups/${props.group.id}/games/store`, {
    preserveScroll: true,
    preserveState: true,
    onSuccess: (component) => {
      event.id = `game_${component.props.flash.success.game.id}`
      events.value.push(event)
      showModal.value = false
      form.participant_one_id = null
      form.participant_two_id = null
      form.observations = null
    }
  })
}

function findParticipant(id) {
  return participants.value.find((participant) => participant.id === id)
}

const calendarOptions = {
  validRange: {
    start: useDate(props.group.phase.date_start).format('YYYY-MM-DD'),
    end: useDate(props.group.phase.date_end).addDay().format('YYYY-MM-DD')
  },
  longPressDelay: 1,
  eventLongPressDelay: 1,
  selectable: true,
  editable: false,
  eventResizableFromStart: true,
  height: '100%',
  locale: esLocale,
  plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin],
  initialView: isMobile.value ? 'timeGridDay' : 'timeGridWeek',
  allDaySlot: false,
  views: {
    timeGridDay: {
      dayHeaderFormat: { weekday: 'long', day: 'numeric', month: 'long', omitCommas: true }
    }
  },
  headerToolbar: {
    left: isMobile.value ? 'today' : 'prev,next',
    center: '',
    right: isMobile.value ? 'prev,next' : 'timeGridWeek,timeGridDay'
  },
  slotLabelFormat: {
    hour: '2-digit',
    minute: '2-digit',
    omitZeroMinute: false,
    hour12: false
  },
  stickyHeaderDates: true,
  slotMinTime: '08:00:00',
  slotMaxTime: '24:00:00',
  eventOverlap: false,
  slotEventOverlap: false,
  selectOverlap: false,
  events: events.value,
  dateClick: handleDateClick,
  eventContent: function (arg) {
    let createElement = document.createElement('div')
    let endTime = arg.event.end ? `${useDate(arg.event.end).format('HH:mm')}` : ''

    const createEventTitleContainer = (title, observations = null, actions = false) => {
      return `
      <div class="fc-event-title-container" style="display: flex; align-items: center; justify-content: space-between; padding: 2px; font-size: 12px">
        <div class="fc-event-title">${title} 
          ${observations ? `| ${observations}</div>` : ''}</div>
        ${
          actions
            ? `<div class="fc-event-delete">Eliminar</div>${
                arg.event.extendedProps.notified
                  ? ''
                  : '<div class="fc-event-notify">Notificar</div>'
              }`
            : ''
        }
      </div>
    `
    }

    if (arg.event.extendedProps.type === 'availability') {
      createElement.innerHTML = createEventTitleContainer(arg.event.title)
    }

    if (arg.event.extendedProps.type === 'game') {
      const eventTitle = `${useDate(arg.event.start).format('HH:mm')} - ${endTime} | ${
        arg.event.title
      }`
      const observations = arg.event.extendedProps.observations
      createElement.innerHTML = createEventTitleContainer(eventTitle, observations, true)
      createElement
        .querySelector('.fc-event-delete')
        ?.addEventListener('click', async function (e) {
          e.stopPropagation()

          if (await useConfirm('¿Estás seguro de que quieres eliminar este evento?')) {
            const gameId = arg.event.id.split('_')[1]

            router.delete(`/rankings/phases/groups/games/${gameId}/delete`, {
              preserveScroll: true,
              preserveState: false,
              onSuccess: () => {
                arg.event.remove()
              }
            })
          }
        })

      createElement
        .querySelector('.fc-event-notify')
        ?.addEventListener('click', async function (e) {
          e.stopPropagation()

          if (await useConfirm('¿Quieres avisar a los participantes de este partido de ranking?')) {
            const gameId = arg.event.id.split('_')[1]

            router.get(`/rankings/phases/groups/games/${gameId}/notify`)
          }
        })
    }

    return { domNodes: [createElement] }
  }
}
</script>

<template>
  <div>
    <Head :title="props.group.name" />

    <h1 class="mb-8 text-xl font-bold">
      <Link class="text-indigo-400 hover:text-indigo-600" href="/rankings"> Rankings </Link>
      <span>/</span>
      <Link
        class="text-indigo-400 hover:text-indigo-600"
        :href="`/rankings/phases/${group.phase.id}/edit`"
      >
        {{ ` ${group.phase.ranking.name} ` }}
      </Link>
      <span>/</span>
      <Link
        class="text-indigo-400 hover:text-indigo-600"
        :href="`/rankings/phases/${group.phase.id}/edit`"
      >
        {{ ` ${group.phase.name} ` }}
      </Link>
      <span>/</span>
      <Link
        class="text-indigo-400 hover:text-indigo-600"
        :href="`/rankings/phases/groups/${group.id}/edit`"
      >
        {{ ` ${group.name} ` }}
      </Link>
      <span>/</span>
      <span> Calendario </span>
    </h1>

    <div class="dashboard">
      <FullCalendar class="h-full" :options="calendarOptions" />
    </div>

    <Modal v-if="showModal">
      <form class="bg-white p-4 rounded-lg max-w-lg w-full" @submit.prevent="createGame">
        <h2 class="text-lg font-bold mb-4">Crear partido</h2>

        <SelectInput
          v-model="form.participant_one_id"
          :error="form.errors.participant_one_id"
          class="pb-8 w-full"
          label="Elige un participante"
        >
          <option v-for="participant in participants" :key="participant.id" :value="participant.id">
            {{ participant.name }}
          </option>
        </SelectInput>

        <SelectInput
          v-model="form.participant_two_id"
          :error="form.errors.participant_two_id"
          class="pb-8 w-full"
          label="Elige un participante "
        >
          <option v-for="participant in participants" :key="participant.id" :value="participant.id">
            {{ participant.name }}
          </option>
        </SelectInput>

        <TextInput
          v-model="form.date_start"
          :error="form.errors.date_start"
          class="pb-8 w-full"
          label="Fecha inicio"
          type="datetime-local"
        />

        <TextInput
          v-model="form.date_end"
          :error="form.errors.date_end"
          class="pb-8 w-full"
          label="Fecha fin"
          type="datetime-local"
        />

        <TextInput
          v-model="form.observations"
          :error="form.errors.observations"
          class="pb-8 pr-6 w-full"
          label="Pista"
        />

        <div class="sm:flex sm:flex-row-reverse space-y-2 sm:space-y-0">
          <LoadingButton
            type="submit"
            :loading="form.processing"
            class="inline-flex w-full justify-center btn-indigo sm:ml-3 sm:w-auto"
          >
            Confirmar
          </LoadingButton>
          <button
            type="button"
            class="inline-flex w-full justify-center btn-white sm:ml-3 sm:w-auto"
            @click=";(showModal = false), form.reset()"
          >
            Cancelar
          </button>
        </div>
      </form>
    </Modal>
  </div>
</template>

<style scoped>
.dashboard,
.main-mobile > div {
  height: calc(100vh - 64px);
}

::v-deep {
  .fc-toolbar-title {
    font-size: 1rem !important;
  }

  .fc-header-toolbar {
    button {
      font-size: 0.82rem !important;
    }
  }

  .fc-event-delete {
    position: absolute;
    right: 3px;
    bottom: 4px;
    color: white;
    background: red;
    padding: 3px 6px;
    border-radius: 4px;
    font-size: 12px;
    cursor: pointer;
  }

  .fc-event-notify {
    position: absolute;
    left: 3px;
    bottom: 4px;
    color: white;
    background: #5661b3;
    padding: 3px 6px;
    border-radius: 4px;
    font-size: 12px;
    cursor: pointer;
  }

  .fc-list .fc-event-delete {
    display: none;
  }

  .fc-list .fc-event-notify {
    display: none;
  }
}
</style>
