<script setup>
// Importa todos los archivos .vue en el directorio '/Shared/Icon'
const iconComponents = import.meta.globEager('./Icon/*.vue')

defineProps({
  name: {
    type: String,
    required: true
  }
})

const components = Object.entries(iconComponents).reduce(
  (components, [path, component]) => {
    // Obtiene el nombre del archivo sin la extensión
    const componentName = path.split('/').pop().replace('.vue', '')
    // Agrega el componente al objeto `components` usando el nombre del componente como clave
    components[componentName] = component.default
    return components
  },
  {}
)
</script>

<template>
  <component :is="components[name]" />
</template>
