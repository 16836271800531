<script setup>
import { Head, Link } from '@inertiajs/vue3'
import MultiselectInput from '@/Shared/MultiselectInput.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import { useForm, router } from '@inertiajs/vue3'
import { useConfirm } from '@/Composables/useConfirm'

const props = defineProps({
  team: {
    type: Object,
    default: () => {}
  },
  players: {
    type: Array,
    default: () => []
  }
})

let form = useForm({
  players: props.team.players
})

function update() {
  form.put(`/rankings/teams/${props.team.id}/update`)
}

async function remove() {
  if (await useConfirm('¿Estas seguro de que quieres eliminar el equipo de ranking?')) {
    router.delete(`/rankings/teams/${props.team.id}/delete`)
  }
}
</script>

<template>
  <div>
    <Head title="Editar Equipo Ranking" />
    <h1 class="mb-8 text-xl font-bold">
      <Link class="text-indigo-400 hover:text-indigo-600" href="/rankings"> Rankings </Link>
      <span>/</span>
      <Link
        class="text-indigo-400 hover:text-indigo-600"
        :href="`/rankings/${team.ranking_id}/edit`"
      >
        {{ ` ${team.ranking.name} ` }}
      </Link>
      <span>/</span>
      Editar Equipo
    </h1>
    <div class="max-w-3xl bg-white rounded-md shadow overflow-hidden">
      <form @submit.prevent="update">
        <div class="flex flex-wrap -mb-8 -mr-6 p-8">
          <MultiselectInput
            v-model="form.players"
            :options="players"
            :error="form.errors.players"
            class="pb-8 pr-6 w-full"
            label="Elige de dos a tres jugadores"
            input-label="fullname"
            track-by="fullname"
          />
        </div>
        <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
          <button class="text-red-600 hover:underline" tabindex="-1" type="button" @click="remove">
            Eliminar
          </button>
          <LoadingButton :loading="form.processing" class="btn-indigo ml-auto" type="submit">
            Actualizar
          </LoadingButton>
        </div>
      </form>
    </div>
  </div>
</template>
