import { createApp, h } from 'vue'
import { createInertiaApp } from '@inertiajs/vue3'
import LayoutApp from '@/Shared/LayoutApp.vue'
import LayoutWeb from '@/Shared/LayoutWeb.vue'
import * as Sentry from '@sentry/vue'

createInertiaApp({
  title: (title) => `${title} - Court Control`,
  resolve: (name) => {
    const pages = import.meta.glob('./Pages/**/*.vue', { eager: true })
    let page = pages[`./Pages/${name}.vue`]
    page.default.layout = determineLayout(name)

    return page
  },
  setup({ el, App, props, plugin }) {
    const app = createApp({ render: () => h(App, props) }).use(plugin)

    Sentry.init({
      app,
      dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
      environment: import.meta.env.VITE_APP_ENV
    })

    app.mount(el)
  }
})

function determineLayout(name) {
  if (name.startsWith('Public/Auth')) {
    return undefined
  }

  if (name.includes('Public')) {
    return LayoutWeb
  }

  return LayoutApp
}
