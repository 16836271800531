<script setup>
import { Head, Link } from '@inertiajs/vue3'
import TextInput from '@/Shared/TextInput.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import { useForm, router } from '@inertiajs/vue3'
import { watch } from 'vue'
import dayjs from 'dayjs'

const props = defineProps({
  coaches: {
    type: Array,
    default: () => []
  },
  filters: {
    type: Object,
    default: null
  },
  levels: {
    type: Array,
    default: () => []
  },
  students: {
    type: Array,
    default: () => []
  },
  weekdays: {
    type: Array,
    default: () => []
  }
})

let form = useForm({
  name: '',
  description: '',
  coach_id: props.coaches[0]?.id,
  level_id: props.filters.level_id || props.levels[0]?.id,
  time_start: '09:00',
  time_end: '10:00',
  date_start: dayjs().add(1, 'day').format('YYYY-MM-DD'),
  date_end: dayjs().add(7, 'day').format('YYYY-MM-DD'),
  weekdays: [],
  students: [],
  max_students: 4
})

watch(
  () => form.level_id,
  (level_id) => {
    form.students = []

    router.get(
      '/lessons/create',
      { level_id },
      {
        preserveState: true,
        preserveScroll: true
      }
    )
  },
  {
    immediate: true
  }
)

function store() {
  form.post('/lessons/store')
}
</script>

<template>
  <div>
    <Head title="Crear clase" />
    <h1 class="mb-8 text-xl font-bold">
      <Link class="text-indigo-400 hover:text-indigo-600" href="/lessons"> Clases </Link>
      <span>/</span> Crear
    </h1>
    <div class="max-w-3xl bg-white rounded-md shadow overflow-hidden">
      <form @submit.prevent="store">
        <div class="flex flex-wrap -mb-8 -mr-6 p-8">
          <SelectInput
            v-model="form.coach_id"
            :error="form.errors.coach_id"
            class="pb-8 pr-6 w-full"
            label="Entrenador"
          >
            <option v-for="coach in coaches" :key="coach.id" :value="coach.id">
              {{ coach.player.fullname }}
            </option>
          </SelectInput>
          <TextInput
            v-model="form.time_start"
            :error="form.errors.time_start"
            type="time"
            class="pb-8 pr-6 w-full lg:w-1/2"
            label="Hora inicio"
          />
          <TextInput
            v-model="form.time_end"
            :error="form.errors.time_end"
            type="time"
            class="pb-8 pr-6 w-full lg:w-1/2"
            label="Hora fin"
          />
          <SelectInput
            v-model="form.weekdays"
            :error="form.errors.weekdays"
            class="pb-8 pr-6 w-full"
            label="Días"
            multiple
          >
            <option v-for="weekday in weekdays" :key="weekday.value" :value="weekday.value">
              {{ weekday.name }}
            </option>
          </SelectInput>
          <SelectInput
            v-model="form.level_id"
            :error="form.errors.level_id"
            class="pb-8 pr-6 w-full lg:w-1/2"
            label="Nivel"
          >
            <option v-for="level in levels" :key="level.id" :value="level.id">
              {{ level.name }}
            </option>
          </SelectInput>
          <TextInput
            v-model="form.max_students"
            :error="form.errors.max_students"
            class="pb-8 pr-6 w-full lg:w-1/2"
            label="Número máximo de alumnos"
            type="number"
          />
          <SelectInput
            v-model="form.students"
            :error="form.errors.students"
            class="pb-8 pr-6 w-full"
            label="Alumnos"
            multiple
          >
            <option v-for="student in students" :key="student.id" :value="student.id">
              {{ student.player.fullname }}
            </option>
          </SelectInput>
          <TextInput
            v-model="form.date_start"
            :error="form.errors.date_start"
            class="pb-8 pr-6 w-full lg:w-1/2"
            label="Fecha inicio"
            type="date"
          />
          <TextInput
            v-model="form.date_end"
            :error="form.errors.date_end"
            class="pb-8 pr-6 w-full lg:w-1/2"
            label="Fecha fin"
            type="date"
          />
        </div>
        <div class="flex items-center justify-end px-8 py-4 bg-gray-50 border-t border-gray-100">
          <LoadingButton :loading="form.processing" class="btn-indigo" type="submit">
            Guardar
          </LoadingButton>
        </div>
      </form>
    </div>
  </div>
</template>
