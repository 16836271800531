<script>
import LayoutWeb from '@/Shared/LayoutWeb.vue'

export default {
  layout: LayoutWeb
}
</script>

<script setup>
import { Head } from '@inertiajs/vue3'
import Markdown from '@/Shared/Markdown.vue'
</script>

<template>
  <Head title="Login" />
  <Markdown path="/markdown/privacy-policy.md" />
</template>
