<script setup>
import { Head, Link, router } from '@inertiajs/vue3'
import { reactive, ref, watch } from 'vue'
import Pagination from '@/Shared/Pagination.vue'
import SearchFilter from '@/Shared/SearchFilter.vue'

const props = defineProps({
  players: {
    type: Object,
    default: () => {}
  },
  filters: {
    type: Object,
    default: () => {}
  }
})

let form = reactive({
  search: props.filters.search
})

const debounce = ref(null)

watch(
  () => form.search,
  (value) => {
    clearTimeout(debounce.value)

    debounce.value = setTimeout(() => {
      router.get('/players', { search: value }, { preserveState: true, preserveScroll: true })
    }, 250)
  }
)
</script>

<template>
  <div>
    <Head title="Jugadores" />
    <h1 class="mb-8 text-xl font-bold">Jugadores</h1>
    <div class="flex items-center justify-between mb-6 space-x-4">
      <SearchFilter
        v-model="form.search"
        class="mr-4 w-full max-w-md"
        @reset="form.search = null"
      />
      <Link class="btn-indigo" href="/players/create">
        <span>Crear</span>
        <span class="hidden md:inline">&nbsp;Jugador</span>
      </Link>
    </div>
    <div class="bg-white rounded-md shadow overflow-x-auto">
      <table class="w-full whitespace-nowrap">
        <thead>
          <tr class="text-left font-bold">
            <th class="pb-4 pt-6 px-6">Nombre</th>
            <th class="pb-4 pt-6 px-6">Apellidos</th>
            <th class="pb-4 pt-6 px-6">Teléfono</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="player in players.data"
            :key="player.id"
            class="hover:bg-gray-100 focus-within:bg-gray-100"
          >
            <td class="border-t">
              <Link
                class="flex items-center px-6 py-4 focus:text-indigo-500"
                :href="`/players/${player.id}/edit`"
              >
                {{ player.name }}
              </Link>
            </td>
            <td class="border-t">
              <Link
                class="flex items-center px-6 py-4"
                :href="`/players/${player.id}/edit`"
                tabindex="-1"
              >
                {{ player.lastname }}
              </Link>
            </td>
            <td class="border-t">
              <Link
                class="flex items-center px-6 py-4"
                :href="`/players/${player.id}/edit`"
                tabindex="-1"
              >
                {{ player.phone }}
              </Link>
            </td>
          </tr>
          <tr v-if="players.data.length === 0">
            <td class="px-6 py-4 border-t" colspan="4">No hay jugadores todavía.</td>
          </tr>
        </tbody>
      </table>
    </div>
    <Pagination class="mt-6" :links="players.links" />
  </div>
</template>
