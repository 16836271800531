<script setup>
import { v4 as uuid } from 'uuid'
import { ref } from 'vue'

defineProps({
  id: {
    type: String,
    default() {
      return `text-input-${uuid()}`
    }
  },
  type: {
    type: String,
    default: 'text'
  },
  error: {
    type: String,
    default: null
  },
  label: {
    type: String,
    default: null
  },
  modelValue: {
    type: [String, Number],
    default: null
  },
  disabled: {
    type: Boolean,
    default: false
  }
})

const attrs = (context) => context.attrs

const emit = defineEmits(['update:modelValue'])

const inputRef = ref(null)

const focus = () => {
  inputRef.value.focus()
}

const select = () => {
  inputRef.value.select()
}

const setSelectionRange = (start, end) => {
  inputRef.value.setSelectionRange(start, end)
}

defineExpose({
  focus,
  select,
  setSelectionRange
})
</script>

<template>
  <div :class="attrs.class">
    <label v-if="label" class="form-label" :for="id">{{ label }}:</label>
    <input
      :id="id"
      ref="input"
      v-bind="attrs"
      class="form-input disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
      :class="{ error: error, 'input-color': type === 'color' }"
      :type="type"
      :value="modelValue"
      :disabled="disabled"
      @input="emit('update:modelValue', $event.target.value)"
    />
    <div v-if="error" class="form-error">{{ error }}</div>
  </div>
</template>

<style scoped>
.input-color {
  height: 44px;
  padding: 0;
}
</style>
