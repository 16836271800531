<script setup>
import Logo from '@/Shared/Logo.vue'
import { ref } from 'vue'

const isOpen = ref(false)
const showLinks = ref(false)

const links = []
</script>

<template>
  <div class="layout-web">
    <div class="main">
      <div class="bg-indigo-800 p-4 text-white">
        <div class="container mx-auto">
          <div class="flex justify-between items-center">
            <div>
              <div>
                <Logo class="text-center text-2xl" />
              </div>
            </div>
            <div v-if="false" class="lg:hidden">
              <button @click="isOpen = !isOpen">
                <svg
                  class="w-6 h-6 fill-current"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Menú</title>
                  <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
                </svg>
              </button>
            </div>
            <div v-show="showLinks" class="hidden lg:block">
              <!-- Navegación para escritorio -->
              <ul v-for="(link, index) in links" :key="index" class="flex space-x-4">
                <li>
                  <a href="#" class="hover:underline">{{ link.text }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div v-if="isOpen" class="bg-indigo-600 mt-2 p-4 lg:hidden">
          <ul v-for="(link, index) in links" :key="index" class="space-y-2">
            <li>
              <a href="#" class="hover:underline">{{ link.text }}</a>
            </li>
          </ul>
        </div>
      </div>

      <div class="px-4 py-6">
        <slot />
      </div>
    </div>

    <footer class="bg-indigo-800 text-white py-6">
      <div class="container mx-auto px-4">
        <div class="text-center">
          <p>&copy; {{ new Date().getFullYear() }} Court Control. Todos los derechos reservados.</p>
        </div>
      </div>
    </footer>
  </div>
</template>

<style>
.layout-web {
  display: grid;
  grid-template-rows: 1fr auto;
  min-height: 100%;
}
</style>
