<script setup>
import { Head, Link } from '@inertiajs/vue3'
import TextInput from '@/Shared/TextInput.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import { useForm } from '@inertiajs/vue3'
import { useDate } from '@/Composables/useDate'
import SelectInput from '@/Shared/SelectInput.vue'

const props = defineProps({
  ranking: {
    type: Object,
    default: () => {}
  },
  players: {
    type: Array,
    default: () => []
  },
  teams: {
    type: Array,
    default: () => []
  },
  phases: {
    type: Array,
    default: () => []
  }
})

let form = useForm({
  name: '',
  date_start: useDate().format('YYYY-MM-DD'),
  date_end: useDate().addDays().format('YYYY-MM-DD'),
  previous_phase_id: null
})

function store() {
  form.post(`/rankings/${props.ranking.id}/phases/store`)
}
</script>

<template>
  <div>
    <Head title="Crear Ranking" />
    <h1 class="mb-8 text-xl font-bold">
      <Link class="text-indigo-400 hover:text-indigo-600" href="/rankings"> Rankings </Link>
      <span>/</span>
      <Link class="text-indigo-400 hover:text-indigo-600" :href="`/rankings/${ranking.id}/edit`">
        {{ ` ${props.ranking.name} ` }}
      </Link>
      <span>/</span>
      Crear Fase
    </h1>
    <div class="max-w-3xl bg-white rounded-md shadow overflow-hidden">
      <form @submit.prevent="store">
        <div class="flex flex-wrap -mb-8 -mr-6 p-8">
          <TextInput
            v-model="form.name"
            :error="form.errors.name"
            class="pb-8 pr-6 w-full"
            label="Nombre"
            type="text"
          />
          <TextInput
            v-model="form.date_start"
            :error="form.errors.date_start"
            class="pb-8 pr-6 w-full lg:w-1/2"
            label="Fecha inicio"
            type="date"
          />
          <TextInput
            v-model="form.date_end"
            :error="form.errors.date_end"
            class="pb-8 pr-6 w-full lg:w-1/2"
            label="Fecha fin"
            type="date"
          />
          <SelectInput
            v-model="form.previous_phase_id"
            :error="form.errors.phase"
            class="pb-8 pr-6 w-full"
            label="Elige la fase previa"
          >
            <option v-for="phase in phases" :key="phase.id" :value="phase.id">
              {{ phase.name }}
            </option>
          </SelectInput>
        </div>
        <div class="flex items-center justify-end px-8 py-4 bg-gray-50 border-t border-gray-100">
          <LoadingButton :loading="form.processing" class="btn-indigo" type="submit">
            Guardar
          </LoadingButton>
        </div>
      </form>
    </div>
  </div>
</template>
