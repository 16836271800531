<script setup>
import { Head, Link, router } from '@inertiajs/vue3'
import FullCalendar from '@fullcalendar/vue3'
import timeGridPlugin from '@fullcalendar/timegrid'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import esLocale from '@fullcalendar/core/locales/es'
import { onUnmounted, ref } from 'vue'
import { useDate } from '@/Composables/useDate'
import { useConfirm } from '@/Composables/useConfirm'

const props = defineProps({
  group: {
    type: Object,
    required: true
  },
  participant: {
    type: Object,
    required: true
  },
  availabilities: {
    type: Array,
    required: true
  }
})

const events = ref(
  props.availabilities.map((availability) => {
    return {
      id: availability.id,
      title: '',
      start: useDate(availability.date_start).format('YYYY-MM-DD HH:mm:ss'),
      end: useDate(availability.date_end).format('YYYY-MM-DD HH:mm:ss')
    }
  })
)

const isMobile = ref(window.innerWidth <= 768)

const updateDeviceType = () => {
  isMobile.value = window.innerWidth <= 768
}

onUnmounted(() => {
  window.removeEventListener('resize', updateDeviceType)
})

const calendarOptions = {
  validRange: {
    start: useDate().format('YYYY-MM-DD'),
    end: useDate(props.group.phase.date_end).addDay().format('YYYY-MM-DD')
  },
  longPressDelay: 1,
  eventLongPressDelay: 1,
  selectable: true,
  editable: false,
  eventResizableFromStart: true,
  height: '100%',
  locale: esLocale,
  plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin],
  initialView: isMobile.value ? 'timeGridDay' : 'timeGridWeek',
  allDaySlot: false,
  views: {
    timeGridDay: {
      dayHeaderFormat: { weekday: 'long', day: 'numeric', month: 'long', omitCommas: true }
    }
  },
  headerToolbar: {
    left: isMobile.value ? 'back' : 'prev,next today',
    center: isMobile.value ? '' : 'back',
    right: isMobile.value ? 'today prev,next' : 'timeGridWeek,timeGridDay'
  },
  customButtons: {
    back: {
      text: 'Ver grupo',
      click: function () {
        router.visit(
          linkWithQuery(`/phases/groups/${props.group.id}/participant/${props.participant.id}/show`)
        )
      }
    }
  },
  //slotDuration: '01:30:00', // Duración de cada intervalo de tiempo de una hora
  slotLabelFormat: {
    hour: '2-digit',
    minute: '2-digit',
    omitZeroMinute: false,
    hour12: false // Use 24-hour time
  },
  stickyHeaderDates: true,
  slotMinTime: '08:00:00', // Hora de inicio de la vista del calendario
  slotMaxTime: '24:00:00', // Hora de fin de la vista del calendario
  eventOverlap: false, // Impide que los eventos se solapen
  slotEventOverlap: false,
  selectOverlap: false,
  dateClick(selectInfo) {
    const calendarApi = selectInfo.view.calendar

    const dateStart = useDate(selectInfo.dateStr)
    const dateEnd = useDate(selectInfo.dateStr).add(1.5, 'hour')

    if (parseInt(dateStart.format('HH')) >= 23) {
      return
    }

    calendarApi.addEvent({
      title: '',
      start: dateStart.format('YYYY-MM-DD HH:mm:ss'),
      end: dateEnd.format('YYYY-MM-DD HH:mm:ss')
    })

    router.put(
      `/phases/groups/${props.group.id}/participant/${props.participant.id}/availability/store`,
      {
        date_start: dateStart.format('YYYY-MM-DD HH:mm:ss'),
        date_end: dateEnd.format('YYYY-MM-DD HH:mm:ss')
      },
      {
        preserveScroll: true,
        preserveState: true
      }
    )
  },
  eventContent: function (arg) {
    let createElement = document.createElement('div')

    let endTime = arg.event.end ? `${useDate(arg.event.end).format('HH:mm')}` : ''

    createElement.innerHTML = `
    <div class="fc-event-title-container" style="display: flex; align-items: center; justify-content: space-between; padding: 2px; font-size: 12px">
      <div class="fc-event-title">${useDate(arg.event.start).format('HH:mm')} -  ${endTime}</div>
      <div class="fc-event-delete">Eliminar</div>
    </div>
  `

    createElement.querySelector('.fc-event-delete').addEventListener('click', async function (e) {
      e.stopPropagation()

      if (await useConfirm('¿Estas seguro de que quieres eliminar esta disponibilidad?')) {
        router.put(
          `/phases/groups/${props.group.id}/participant/${props.participant.id}/availability/delete`,
          {
            date_start: useDate(arg.event.start).format('YYYY-MM-DD HH:mm:ss'),
            date_end: useDate(arg.event.end).format('YYYY-MM-DD HH:mm:ss')
          },
          {
            preserveScroll: true
          }
        )

        arg.event.remove()
      }
    })

    return { domNodes: [createElement] }
  },
  events: events.value
}

function linkWithQuery(path) {
  const currentSearchParams = new URLSearchParams(window.location.search)

  const queryString = currentSearchParams.toString()
  return queryString ? `${path}?${queryString}` : path
}
</script>

<template>
  <div>
    <Head :title="props.group.name" />

    <div class="max-w-5xl mx-auto">
      <h1 class="mb-8 text-xl font-bold">
        <span>
          {{ group.phase.ranking.name }} / {{ group.phase.name }} / {{ group.name }} /
          <Link
            class="text-indigo-400 hover:text-indigo-600"
            :href="linkWithQuery(`/phases/groups/${group.id}/participant/${participant.id}/show`)"
          >
            {{ participant.name }}
          </Link>
        </span>
      </h1>

      <div class="text-italic text-sm mb-8">Pincha en la agenda para crear una disponibilidad.</div>
    </div>

    <div class="dashboard">
      <FullCalendar class="h-full" :options="calendarOptions" />
    </div>
  </div>
</template>

<style scoped>
.dashboard,
.main-mobile > div {
  height: calc(100vh - 64px);
}

::v-deep {
  .fc-toolbar-title {
    font-size: 1rem !important;
  }

  .fc-header-toolbar {
    button {
      font-size: 0.82rem !important;
    }
  }

  .fc-event-delete {
    position: absolute;
    right: 3px;
    bottom: 4px;
    color: white;
    background: red;
    padding: 2px 4px;
    border-radius: 4px;
    font-size: 12px;
    cursor: pointer;
  }
}
</style>
