<script setup>
import { v4 as uuid } from 'uuid'
import { ref, watch, onMounted, useAttrs } from 'vue'

const props = defineProps({
  id: {
    type: String,
    default() {
      return `select-input-${uuid()}`
    }
  },
  error: {
    type: String,
    default: null
  },
  label: {
    type: String,
    default: null
  },
  placeholder: {
    type: String,
    default: null
  },
  multiple: {
    type: Boolean,
    default: false
  },
  modelValue: {
    type: [String, Number, Boolean, Array],
    default: null
  }
})

const emit = defineEmits(['update:modelValue'])

const selected = ref(props.modelValue !== null ? props.modelValue : '')

watch(
  () => props.modelValue,
  (newVal) => {
    selected.value = newVal !== null ? newVal : ''
  }
)

watch(selected, (value) => {
  emit('update:modelValue', value !== '' ? value : null)
})

const focus = () => {
  refs.input.focus()
}

onMounted(() => {
  if (attrs.autofocus) {
    focus()
  }
})

const attrs = {
  ...useAttrs(),
  multiple: props.multiple,
  class: null
}

const refs = {
  input: null
}
</script>

<template>
  <div :class="attrs.class">
    <label v-if="label" class="form-label" :for="id">{{ label }}:</label>
    <select
      :id="id"
      ref="input"
      v-model="selected"
      v-bind="attrs"
      class="form-select"
      :class="{ error: error, 'bg-none': multiple }"
    >
      <option v-if="placeholder" disabled value="">{{ placeholder }}</option>
      <slot />
    </select>
    <div v-if="error" class="form-error">{{ error }}</div>
  </div>
</template>
