<script setup>
import { v4 as uuid } from 'uuid'
import { ref, watch, defineProps, defineEmits } from 'vue'
import VueMultiselect from 'vue-multiselect'

const props = defineProps({
  id: {
    type: String,
    default: () => `textarea-input-${uuid()}`
  },
  modelValue: {
    type: [String, Number, Array, Object],
    default: null
  },
  options: {
    type: Array,
    default: () => []
  },
  multiple: {
    type: Boolean,
    default: true
  },
  label: {
    type: String,
    required: true
  },
  inputLabel: {
    type: String,
    default: 'name'
  },
  trackBy: {
    type: String,
    default: 'name'
  },
  error: {
    type: String,
    default: null
  }
})

const emit = defineEmits(['update:modelValue'])

const selectedValue = ref(props.modelValue)

watch(selectedValue, (newValue) => {
  emit('update:modelValue', newValue)
})
</script>

<template>
  <div>
    <label v-if="label" class="form-label" :for="id">{{ label }}:</label>
    <VueMultiselect
      v-model="selectedValue"
      :options="options"
      :multiple="multiple"
      :label="inputLabel"
      :track-by="trackBy"
      :hide-selected="true"
      select-label=""
      placeholder=""
    >
      <template #noResult>Ups! No se encontraron elementos. </template>
    </VueMultiselect>
    <div v-if="error" class="form-error">{{ error }}</div>
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
