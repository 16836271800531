<script setup>
import { v4 as uuid } from 'uuid'
import { ref } from 'vue'

const props = defineProps({
  id: {
    type: String,
    default: () => `textarea-input-${uuid()}`
  },
  error: {
    type: String,
    default: null
  },
  label: {
    type: String,
    default: null
  },
  modelValue: {
    type: String,
    default: null
  },
  disabled: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['update:modelValue'])

const focus = () => {
  inputRef.value.focus()
}

const select = () => {
  inputRef.value.select()
}

const attrs = {
  ...props,
  focus,
  select
}

const inputRef = ref(null)
</script>

<template>
  <div :class="attrs.class">
    <label v-if="attrs.label" class="form-label" :for="attrs.id"> {{ attrs.label }}: </label>
    <textarea
      :id="attrs.id"
      ref="input"
      v-bind="attrs"
      :value="modelValue"
      class="form-input disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
      :disabled="disabled"
      :class="{ error: error }"
      @input="emit('update:modelValue', $event.target.value)"
    />
    <div v-if="error" class="form-error">{{ error }}</div>
  </div>
</template>
