<script setup>
import { Link } from '@inertiajs/vue3'
import Icon from '@/Shared/Icon.vue'
import { usePage } from '@inertiajs/vue3'

const links = [
  {
    url: '/',
    includedComponents: ['Dashboard'],
    text: 'Agenda',
    icon: 'RocketLaunch'
  },
  {
    url: '/lessons',
    includedComponents: ['Lessons/Index', 'Lessons/Create', 'Lessons/Edit', 'Lessons/Levels/*'],
    text: 'Clases',
    icon: 'SquareStack'
  },
  {
    url: '/players',
    includedComponents: ['Players/*'],
    text: 'Jugadores',
    icon: 'GlobeAmericas'
  },
  {
    url: '/coaches',
    includedComponents: ['Coaches/*'],
    text: 'Entrenadores',
    icon: 'AcademicCap'
  },
  {
    url: '/students',
    includedComponents: ['Students/*'],
    text: 'Alumnos',
    icon: 'UserGroup'
  },
  {
    url: '/rankings',
    includedComponents: ['Rankings/*'],
    text: 'Rankings',
    icon: 'ChartBar'
  },
  {
    url: '/logout',
    method: 'post',
    includedComponents: [],
    text: 'Salir',
    icon: 'ArrowLeftOnRectangle'
  }
]

function isActive(link) {
  const currentComponent = usePage().component
  if (link.includedComponents.some((component) => component.includes('*'))) {
    return link.includedComponents.some((component) =>
      currentComponent.startsWith(component.slice(0, -1))
    )
  }
  return link.includedComponents.includes(currentComponent)
}

const activeColor = 'text-white fill-white'
const inactiveColor = 'text-indigo-300 fill-indigo-400'
</script>

<template>
  <div class="relative">
    <div v-for="(link, index) in links" :key="index" class="mb-4">
      <Link class="group flex items-center py-3" :href="link.url" :method="link.method || 'get'">
        <Icon
          :name="link.icon"
          class="mr-2 w-4 h-4"
          :class="[isActive(link) ? activeColor : inactiveColor, 'group-hover:fill-white']"
        />
        <div :class="[isActive(link) ? activeColor : inactiveColor, 'group-hover:text-white']">
          {{ link.text }}
        </div>
      </Link>
    </div>
  </div>
</template>
