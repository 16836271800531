<script setup>
import { Head, Link, router } from '@inertiajs/vue3'
import TextInput from '@/Shared/TextInput.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import { useForm } from '@inertiajs/vue3'
import { useConfirm } from '@/Composables/useConfirm'

const props = defineProps({
  player: {
    type: Object,
    required: true
  }
})

let form = useForm({
  name: props.player.name,
  lastname: props.player.lastname,
  phone: props.player.phone,
  telegram_chat_id: props.player.telegram_chat_id
})

function update() {
  form.put(`/players/${props.player.id}/update`)
}

async function remove() {
  if (await useConfirm('¿Estas seguro de que quieres eliminar el jugador?')) {
    router.delete(`/players/${props.player.id}/delete`)
  }
}
</script>

<template>
  <div>
    <Head :title="form.name" />
    <h1 class="mb-8 text-xl font-bold">
      <Link class="text-indigo-400 hover:text-indigo-600" href="/players"> Jugadores </Link>
      <span>/</span>
      {{ form.name }}
    </h1>
    <div class="max-w-3xl bg-white rounded-md shadow overflow-hidden">
      <form @submit.prevent="update">
        <div class="flex flex-wrap -mb-8 -mr-6 p-8">
          <TextInput
            v-model="form.name"
            :error="form.errors.name"
            class="pb-8 pr-6 w-full lg:w-1/2"
            label="Nombre"
          />
          <TextInput
            v-model="form.lastname"
            :error="form.errors.lastname"
            class="pb-8 pr-6 w-full lg:w-1/2"
            label="Apellidos"
          />
          <TextInput
            v-model="form.phone"
            :error="form.errors.phone"
            class="pb-8 pr-6 w-full lg:w-1/2"
            label="Teléfono"
          />
          <TextInput
            :model-value="form.telegram_chat_id"
            :error="form.errors.telegram_chat_id"
            class="pb-8 pr-6 w-full lg:w-1/2"
            label="Telegram ID"
            disabled
          />
        </div>
        <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
          <button class="text-red-600 hover:underline" tabindex="-1" type="button" @click="remove">
            Eliminar
          </button>
          <LoadingButton :loading="form.processing" class="btn-indigo ml-auto" type="submit">
            Actualizar
          </LoadingButton>
        </div>
      </form>
    </div>
  </div>
</template>
