<script setup>
import { Head } from '@inertiajs/vue3'
import FullCalendar from '@fullcalendar/vue3'
import timeGridPlugin from '@fullcalendar/timegrid'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import esLocale from '@fullcalendar/core/locales/es'
import { onMounted, onUnmounted, ref } from 'vue'

const props = defineProps({
  records: {
    type: Array,
    default: () => []
  }
})

const events = ref([])

const isMobile = ref(window.innerWidth <= 768)

const updateDeviceType = () => {
  isMobile.value = window.innerWidth <= 768
}

onUnmounted(() => {
  window.removeEventListener('resize', updateDeviceType)
})

const calendarOptions = {
  height: '100%',
  locale: esLocale,
  plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin],
  initialView: isMobile.value ? 'listWeek' : 'dayGridMonth',
  allDaySlot: false,
  headerToolbar: isMobile.value
    ? {
        left: 'prev,next today',
        center: '',
        right: 'title'
      }
    : {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
      },
  dateClick(info) {
    console.log(info)
  },
  events: events.value
}

onMounted(() => {
  window.addEventListener('resize', updateDeviceType)

  props.records.forEach((record) => {
    events.value.push({
      title: record.lesson.level.name,
      start: record.date_start,
      end: record.date_end,
      backgroundColor: record.lesson.level.color,
      url: `/lessons/records/${record.id}/edit`
    })
  })
})
</script>

<template>
  <div class="dashboard">
    <Head title="Dashboard" />
    <div class="h-full">
      <FullCalendar class="h-full" :options="calendarOptions" />
    </div>
  </div>
</template>

<style scoped>
.dashboard,
.main-mobile > div {
  height: calc(100vh - 64px);
}

::v-deep {
  .fc .fc-toolbar-title {
    font-size: 1.25rem;
  }
}
</style>
