<script setup>
import { marked } from 'marked'
import { computed, onMounted, ref } from 'vue'

const props = defineProps({
  path: {
    type: String,
    required: true
  }
})

const markdownContent = ref('')

onMounted(async () => {
  try {
    const response = await fetch(props.path)
    const content = await response.text()
    markdownContent.value = content
  } catch (error) {
    console.error('Error fetching the markdown file:', error)
  }
})

const convertedMarkdown = computed(() => marked.parse(markdownContent.value))
</script>

<template>
  <div class="w-full max-w-3xl mx-auto">
    <div v-if="markdownContent" class="markdown-content" v-html="convertedMarkdown"></div>
  </div>
</template>
