<script setup>
import { Head, Link, router } from '@inertiajs/vue3'
import TextInput from '@/Shared/TextInput.vue'
import TextareaInput from '@/Shared/TextareaInput.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import { useForm } from '@inertiajs/vue3'
import { useConfirm } from '@/Composables/useConfirm'

const props = defineProps({
  level: {
    type: Object,
    required: true
  }
})

let form = useForm({
  name: props.level.name,
  description: props.level.description,
  order: props.level.order,
  color: props.level.color
})

function update() {
  form.put(`/lessons/levels/${props.level.id}/update`)
}

async function deleteLevel() {
  if (await useConfirm('¿Estas seguro de que quieres eliminar la clase?')) {
    router.delete(`/lessons/levels/${props.level.id}/delete`)
  }
}
</script>

<template>
  <div>
    <Head :title="form.name" />
    <h1 class="mb-8 text-xl font-bold">
      <Link class="text-indigo-400 hover:text-indigo-600" href="/lessons"> Clases </Link>
      <span>/</span>
      <Link class="text-indigo-400 hover:text-indigo-600" href="/lessons/levels"> Niveles </Link>
      <span>/</span>
      {{ form.name }}
    </h1>
    <div class="max-w-3xl bg-white rounded-md shadow overflow-hidden">
      <form @submit.prevent="update">
        <div class="flex flex-wrap -mb-8 -mr-6 p-8">
          <TextInput
            v-model="form.name"
            :error="form.errors.name"
            class="pb-8 pr-6 w-full lg:w-1/2"
            label="Nombre"
          />
          <TextareaInput
            v-model="form.description"
            :error="form.errors.description"
            class="pb-8 pr-6 w-full"
            label="Descripción"
          />
          <TextInput
            v-model="form.order"
            :error="form.errors.order"
            class="pb-8 pr-6 w-full lg:w-1/2"
            label="Orden"
          />
          <TextInput
            v-model="form.color"
            :error="form.errors.color"
            class="pb-8 pr-6 w-full lg:w-1/2"
            label="Color"
            type="color"
          />
        </div>
        <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
          <button
            class="text-red-600 hover:underline"
            tabindex="-1"
            type="button"
            @click="deleteLevel"
          >
            Eliminar
          </button>
          <LoadingButton :loading="form.processing" class="btn-indigo ml-auto" type="submit">
            Actualizar
          </LoadingButton>
        </div>
      </form>
    </div>
  </div>
</template>
