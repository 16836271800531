<script setup>
import { Head, Link, router } from '@inertiajs/vue3'
import Icon from '@/Shared/Icon.vue'
import ToggleInput from '@/Shared/ToggleInput.vue'
import Pagination from '@/Shared/Pagination.vue'
import { watch } from 'vue'
import { useDate } from '@/Composables/useDate'

const props = defineProps({
  lessons: {
    type: Object,
    default: () => {}
  }
})

props.lessons.data.forEach((lesson) => {
  watch(
    () => lesson.enabled,
    () => {
      router.put(`/lessons/${lesson.id}/enable`, {
        preserveState: true,
        preserveScroll: true
      })
    }
  )
})
</script>

<template>
  <div>
    <Head title="Clases" />
    <h1 class="mb-8 text-xl font-bold">Clases</h1>
    <div class="flex items-center justify-end mb-6 space-x-4">
      <Link class="btn-indigo" href="/lessons/create">
        <span>Crear</span>
        <span class="hidden md:inline">&nbsp;clase</span>
      </Link>
      <Link class="btn-indigo" href="/lessons/levels">
        <span>Niveles</span>
      </Link>
    </div>
    <div class="bg-white rounded-md shadow overflow-x-auto">
      <table class="w-full whitespace-nowrap">
        <thead>
          <tr class="text-left font-bold">
            <th class="pb-4 pt-6 px-6">Duración</th>
            <th class="pb-4 pt-6 px-6">Hora</th>
            <th class="pb-4 pt-6 px-6">Nivel</th>
            <th class="pb-4 pt-6 px-6">Entrenador</th>
            <th class="pb-4 pt-6 px-6">Activada</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="lesson in lessons.data"
            :key="lesson.id"
            class="hover:bg-gray-100 focus-within:bg-gray-100"
          >
            <td class="border-t">
              <Link
                class="flex items-center px-6 py-4 focus:text-indigo-500"
                :href="`/lessons/${lesson.id}/edit`"
              >
                {{ useDate(lesson.date_start).format('DD-MM-YYYY') }} /
                {{ useDate(lesson.date_end).format('DD-MM-YYYY') }}
              </Link>
            </td>
            <td class="border-t">
              <Link
                class="flex items-center px-6 py-4"
                :href="`/lessons/${lesson.id}/edit`"
                tabindex="-1"
              >
                {{ lesson.time_start }} - {{ lesson.time_end }}
              </Link>
            </td>
            <td class="border-t">
              <Link
                class="flex items-center px-6 py-4"
                :href="`/lessons/${lesson.id}/edit`"
                tabindex="-1"
              >
                {{ lesson.level.name }}
              </Link>
            </td>
            <td class="border-t">
              <Link
                class="flex items-center px-6 py-4"
                :href="`/lessons/${lesson.id}/edit`"
                tabindex="-1"
              >
                {{ lesson.coach.player.fullname }}
              </Link>
            </td>
            <td class="w-px border-t">
              <ToggleInput v-model="lesson.enabled" class="w-full flex justify-center" />
            </td>
            <td class="w-px border-t">
              <Link
                class="flex items-center px-4"
                :href="`/lessons/${lesson.id}/edit`"
                tabindex="-1"
              >
                <Icon name="cheveron-right" class="block w-6 h-6 fill-gray-400" />
              </Link>
            </td>
          </tr>
          <tr v-if="lessons.data.length === 0">
            <td class="px-6 py-4 border-t" colspan="4">No hay clases todavía.</td>
          </tr>
        </tbody>
      </table>
    </div>
    <Pagination class="mt-6" :links="lessons.links" />
  </div>
</template>
