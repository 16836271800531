import dayjs from 'dayjs'
import { ref } from 'vue'
import relativeTime from 'dayjs/plugin/relativeTime'
import 'dayjs/locale/es'

dayjs.locale('es')
dayjs.extend(relativeTime)

function createDate(value) {
  const date = ref(dayjs(value))

  Object.assign(date, {
    format: function (format) {
      return this.value.format(format)
    },
    add: function (unit, number) {
      this.value = this.value.add(unit, number)
      return this.value
    },
    hour: function () {
      return this.value.hour()
    },
    addDay: function () {
      this.value = this.value.add(1, 'day')
      return this.value
    },
    addDays: function (number) {
      this.value = this.value.add(number, 'day')
      return this.value
    },
    diffForHumans: function (otherDate) {
      return this.value.from(otherDate)
    }
  })

  return date
}

export function useDate(value) {
  return createDate(value)
}
