<script setup>
import { Head, Link } from '@inertiajs/vue3'
import MultiselectInput from '@/Shared/MultiselectInput.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import { useForm } from '@inertiajs/vue3'

const props = defineProps({
  ranking: {
    type: Object,
    default: () => {}
  },
  players: {
    type: Array,
    default: () => []
  }
})

let form = useForm({
  players: []
})

function store() {
  form.post(`/rankings/${props.ranking.id}/teams/store`)
}
</script>

<template>
  <div>
    <Head title="Crear equipo de ranking" />
    <h1 class="mb-8 text-xl font-bold">
      <Link class="text-indigo-400 hover:text-indigo-600" href="/rankings"> Rankings </Link>
      <span>/</span>
      <Link class="text-indigo-400 hover:text-indigo-600" :href="`/rankings/${ranking.id}/edit`">
        {{ ` ${props.ranking.name} ` }}
      </Link>
      <span>/</span>
      Crear Equipo
    </h1>
    <div class="max-w-3xl bg-white rounded-md shadow overflow-hidden">
      <form @submit.prevent="store">
        <div class="flex flex-wrap -mb-8 -mr-6 p-8">
          <MultiselectInput
            v-model="form.players"
            :options="players"
            :error="form.errors.players"
            class="pb-8 pr-6 w-full"
            label="Elige de dos a tres jugadores"
            input-label="fullname"
            track-by="fullname"
          />
        </div>
        <div class="flex items-center justify-end px-8 py-4 bg-gray-50 border-t border-gray-100">
          <LoadingButton :loading="form.processing" class="btn-indigo" type="submit">
            Guardar
          </LoadingButton>
        </div>
      </form>
    </div>
  </div>
</template>
