<script setup>
import { Head, Link } from '@inertiajs/vue3'
import TextInput from '@/Shared/TextInput.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import { useForm } from '@inertiajs/vue3'

defineProps({
  roles: {
    type: Array,
    required: true
  }
})

let form = useForm({
  name: '',
  lastname: '',
  phone: ''
})

function store() {
  form.post('/players/store')
}
</script>

<template>
  <div>
    <Head title="Crear jugador" />
    <h1 class="mb-8 text-xl font-bold">
      <Link class="text-indigo-400 hover:text-indigo-600" href="/players"> Jugadores </Link>
      <span>/</span> Crear
    </h1>
    <div class="max-w-3xl bg-white rounded-md shadow overflow-hidden">
      <form @submit.prevent="store">
        <div class="flex flex-wrap -mb-8 -mr-6 p-8">
          <TextInput
            v-model="form.name"
            :error="form.errors.name"
            class="pb-8 pr-6 w-full lg:w-1/2"
            label="Nombre"
          />
          <TextInput
            v-model="form.lastname"
            :error="form.errors.lastname"
            class="pb-8 pr-6 w-full lg:w-1/2"
            label="Apellidos"
          />
          <TextInput
            v-model="form.phone"
            :error="form.errors.phone"
            class="pb-8 pr-6 w-full lg:w-1/2"
            label="Teléfono"
          />
        </div>
        <div class="flex items-center justify-end px-8 py-4 bg-gray-50 border-t border-gray-100">
          <LoadingButton :loading="form.processing" class="btn-indigo" type="submit">
            Guardar
          </LoadingButton>
        </div>
      </form>
    </div>
  </div>
</template>
