import Swal from 'sweetalert2'
import * as Sentry from '@sentry/vue'

export async function useConfirm(message, options = {}) {
  const defaultOptions = {
    title: '¿Estás seguro?',
    text: message,
    icon: 'warning',
    showCancelButton: true,
    customClass: {
      confirmButton: 'btn-indigo',
      cancelButton: 'btn-white'
    },
    confirmButtonText: 'Confirmar',
    cancelButtonText: 'Cancelar',
    showLoaderOnConfirm: false,
    allowOutsideClick: () => !Swal.isLoading()
  }

  const swalOptions = { ...defaultOptions, ...options }

  if (options.preConfirmCallback) {
    swalOptions.preConfirm = async () => {
      try {
        await options.preConfirmCallback()
      } catch (error) {
        Swal.showValidationMessage(`Ups: Ha habido un error`)
        Sentry.captureException(error)
      }
    }
  }

  const result = await Swal.fire(swalOptions)

  if (result.isConfirmed && options.onSuccessMessage) {
    await Swal.fire({
      title: 'Operación Exitosa',
      text: options.onSuccessMessage,
      icon: 'success',
      confirmButtonText: 'OK',
      customClass: {
        confirmButton: 'btn-indigo',
        cancelButton: 'btn-white'
      }
    })
  }

  return result.isConfirmed
}
