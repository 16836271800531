<script setup>
import { Head, router, useForm } from '@inertiajs/vue3'
import TextInput from '@/Shared/TextInput.vue'
import ConfirmModal from '@/Shared/ConfirmModal.vue'
import Icon from '@/Shared/Icon.vue'
import { useDate } from '@/Composables/useDate'
import { ref } from 'vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import { useConfirm } from '@/Composables/useConfirm'

const props = defineProps({
  record: {
    type: Object,
    required: true
  },
  students: {
    type: Array,
    default: () => []
  }
})

const showStudents = ref(false)

async function remove(student) {
  if (await useConfirm('¿Estas seguro de que quieres eliminar el alumno de esta clase?')) {
    router.delete(`/lessons/records/${props.record.id}/student/${student.id}/delete`)
  }
}

let form = useForm({
  date_start: useDate(props.record.date_start).format('YYYY-MM-DD'),
  time_start: useDate(props.record.date_start).format('HH:mm'),
  time_end: useDate(props.record.date_end).format('HH:mm')
})

function update() {
  form.put(`/lessons/records/${props.record.id}/update`)
}

let formNotify = useForm({
  students: props.students.map((s) => s.id)
})

function submit() {
  formNotify.post(`/lessons/records/${props.record.id}/students/notify`, {
    preserveState: false
  })
}
</script>

<template>
  <div>
    <ConfirmModal
      v-if="showStudents"
      :loading="formNotify.processing"
      @close="showStudents = false"
      @confirm="submit"
    >
      <template #title> Selecciona los alumnos </template>
      <template #body>
        <div class="text-sm mt-4">Selecciona a los alumnos a los que enviar una notificación.</div>
        <div class="overflow-y-auto" style="max-height: 70vh">
          <div v-for="(student, index) in students" :key="index">
            <label class="flex items-center mt-6 select-none">
              <input
                v-model="formNotify.students"
                :value="student.id"
                class="mr-1"
                type="checkbox"
              />
              <span class="text-sm">{{ student.player.fullname }}</span>
            </label>
          </div>
        </div>
      </template>
    </ConfirmModal>

    <Head :title="record.lesson.level.name" />
    <h1 class="mb-8 text-xl font-bold">
      {{ record.lesson.level.name }} / {{ useDate(record.date_start).format('DD-MM-YYYY') }} /
      {{ useDate(record.date_start).format('HH:mm') }} -
      {{ useDate(record.date_end).format('HH:mm') }}
    </h1>
    <div class="max-w-3xl bg-white rounded-md shadow overflow-hidden">
      <form @submit.prevent="update">
        <div class="flex flex-wrap -mb-8 -mr-6 p-8">
          <TextInput v-model="form.date_start" type="date" class="pb-8 pr-6 w-full" label="Día" />
          <TextInput
            v-model="form.time_start"
            type="time"
            class="pb-8 pr-6 w-full lg:w-1/2"
            label="Hora inicio"
          />
          <TextInput
            v-model="form.time_end"
            type="time"
            class="pb-8 pr-6 w-full lg:w-1/2"
            label="Hora fin"
          />
          <TextInput
            disabled
            :model-value="record.lesson.coach.player.fullname"
            class="pb-8 pr-6 w-full lg:w-1/2"
            label="Entrenador"
          />
          <TextInput
            disabled
            :model-value="props.record.lesson.level.name"
            class="pb-8 pr-6 w-full lg:w-1/2"
            label="Nivel"
          />
        </div>
        <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
          <LoadingButton :loading="form.processing" class="btn-indigo ml-auto" type="submit">
            Actualizar
          </LoadingButton>
        </div>
      </form>
    </div>
    <h1 class="mt-8 text-xl font-bold">Alumnos</h1>
    <div class="max-w-3xl flex items-center justify-end mb-6 space-x-4">
      <button type="button" class="btn-indigo" @click="showStudents = !showStudents">
        <span>Solicitar alumnos</span>
      </button>
    </div>
    <div class="max-w-3xl bg-white rounded-md shadow overflow-hidden mt-6 p-8">
      <div class="grid sm:grid-cols-2 sm:gap-x-6">
        <div
          v-for="(student, index) in record.students"
          :key="index"
          class="flex flex-row justify-between mb-8 border px-4 py-3"
        >
          <span type="text" class="bg-transparent">
            {{ student.player.fullname }}
          </span>

          <button class="ml-4" @click="remove(student)">
            <Icon class="w-4 h-4" name="Trash" />
          </button>
        </div>
        <div v-if="!record.students.length">No hay alumnos todavía.</div>
      </div>
    </div>

    <h1 class="mt-8 text-xl font-bold">Solicitudes</h1>
    <div class="max-w-3xl bg-white rounded-md shadow overflow-hidden mt-6 p-8 space-y-2">
      <div v-for="note in record.notes" :key="note.id">
        <div class="leading-6">
          <p v-html="note.message" />
          <span class="text-gray-500"> {{ useDate(note.created_at).diffForHumans() }} </span>
        </div>
      </div>
      <div v-if="!record.notes.length">No hay solicitudes pendientes.</div>
    </div>
  </div>
</template>
